<template>
    <AuthorView v-if="author" :item="author"/>
    <Center v-else-if="!author && !loading">
        Elemento Não encontrado.
    </Center>
</template>
<script>
import AuthorView from '../components/author/View';
import Center from '../components/CenteredComponent';


import { mapState, mapActions } from 'vuex';

export default {
    created() {
        this.fetchDetail();
    },
    computed: {
        username() {
            return this.$route.params.username;
        },
        ...mapState('author', ['author', 'loading'])
    },
    methods: {
        ...mapActions('author', ['detail']),
        fetchDetail() {
            this.detail({username: this.username});
        }
    },
    components: {
        AuthorView,
        Center
    }
}
</script>